import { Navigate, Route, Routes } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import LayoutRoutes from "./LayoutRoutes";
import { authRoutes } from "./AuthRoutes";
import Login from "../Componant/Authentication/Login";
import RegisterSimple from "../Pages/OtherPages/Authentication/RegisterSimple/RegisterSimple";
import { FRONTEND_MAIN_URL } from "../Utils";

const RouterData = () => {
  const login = JSON.parse(localStorage.getItem("login")!) || false;
  const user = login ? JSON.parse(localStorage.getItem("login-user")!) : null;
  const isAdmin = user?.user?.isAdmin;
  console.log("loginloginlogin", login);
  // if (!login) {
  //   // Redirect to the external login page if the user is not logged in
  //   window.location.href = `${FRONTEND_MAIN_URL}/auth/login`;
  //   return null; // Prevent rendering React Router components
  // }
  return (
      <Routes>
        {login && (
          <Route
            path={`${process.env.PUBLIC_URL}` || '/'}
            element={
              <Navigate to={isAdmin ? `${process.env.PUBLIC_URL}/masteradmin/dashboard/home` : `${process.env.PUBLIC_URL}/dashboard/home`} />
            }
          />
        )}
        <Route path={"/"} element={<PrivateRoute />}>
          <Route path={`/*`} element={<LayoutRoutes />} />
        </Route>
        {authRoutes.map(({ path, Component }, i) => (
          <Route path={path} element={Component} key={i} />
        ))}
      </Routes>
  );
};

export default RouterData;
