import { Col, Container, Row } from "reactstrap";
import { P, SVG } from "../../AbstractElements";
import { 
  Twitter, Facebook, Instagram, Youtube, Github
} from 'lucide-react';

const Footer = () => {
  const socialLinks = [
    { label: 'Twitter', href: 'https://x.com/ProSportsAPI', icon: Twitter },
    { label: 'Facebook', href: 'https://www.facebook.com/ProSportsAPI', icon: Facebook },
    { label: 'Instagram', href: 'https://www.instagram.com/prosportsapi/', icon: Instagram },
    { label: 'Youtube', href: '#', icon: Youtube },
    { label: 'Github', href: 'https://github.com/ProSportsAPI', icon: Github },
    { label: 'Reddit', href: 'https://www.reddit.com/r/ProSportsAPI/', icon: Github }
  ];
  return (
    <footer className="footer border-top border-gray-700 py-4">
      <Container fluid>
        <Row className="align-items-center justify-content-between">
          {/* Social Media Links */}
          <Col md="6" className="d-flex justify-content-center justify-content-md-start mb-3 mb-md-0">
            <div className="d-flex">
              {socialLinks.map((platform) => (
                <a
                  key={platform.label}
                  href={platform.href}
                  className="text-gray-400 hover:text-white transition p-2 bg-transparent hover-bg-gray-800 rounded-circle me-2"
                  title={platform.label}
                >
                  <platform.icon className="w-5 h-5" />
                </a>
              ))}
            </div>
          </Col>

          {/* Copyright Section */}
          <Col md="6" className="text-center text-md-end">
            <P className="mb-0 text-sm">
              Copyright © 2024 ProSports API. All rights reserved.
            </P>
          </Col>
        </Row>

        {/* Powered By Section */}
        <Row className="border-top border-gray-700 pt-3 mt-3">
          <Col md="12" className="text-center">
            <P className="mb-0 text-sm">Powered by Albatross Media</P>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
