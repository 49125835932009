import { Provider, useDispatch, useSelector } from "react-redux";
import RouterData from "./Routes";
import { ToastContainer } from "react-toastify";
import Store, { AppDispatch, RootState } from "./ReduxToolkit/Store";
import { useEffect } from "react";
import { getAllUsers, sharedLogin } from "./ReduxToolkit/Reducers/Change/AuthSlice";
import { getSubscribe } from "./ReduxToolkit/Reducers/Change/Subscribe";
import { getLoggedUserProfile } from "./ReduxToolkit/Reducers/Change/ProfileSlice";
import "./App.css"
import { FRONTEND_MAIN_URL } from "./Utils";
import { useLocation, useNavigate } from "react-router-dom";

const App = () => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const navigate = useNavigate();
  // if (!tokenFromURL) {
  //   window.location.href = `${FRONTEND_MAIN_URL}/auth/login`;
  // }

  const sharedLoginFn = async () => {
    try {
      const queryParams = new URLSearchParams(location.search);
      console.log("queryParams", queryParams);
      
      const tokenFromURL: any = queryParams.get('token') || null;
      console.log("tokenFromURL", tokenFromURL);
      if (!tokenFromURL) {
        window.location.href = `${FRONTEND_MAIN_URL}/auth/login`;
      }
      const res = await dispatch(sharedLogin(tokenFromURL)).unwrap();
      console.log("res---- logina", res);
      const userJsonString = JSON.stringify({ user: res.data, token: res.token });
      localStorage.setItem("login", JSON.stringify(true));
      localStorage.setItem("login-user", userJsonString);
      navigate(`${process.env.PUBLIC_URL}/dashboard/home`);
      // Once sharedLoginFn completes, fetch referers and plans
      await fetchRefferer();
      await fetchPlans();
    } catch (error) {
      console.log("Error from sharedLoginFn", error);
    }
  };

  const fetchRefferer = async () => {
    await dispatch(getAllUsers());
  };

  const fetchPlans = async () => {
    await dispatch(getSubscribe());
  };
  useEffect(() => {
    const login = localStorage.getItem("login") || false;
    const login_user = localStorage.getItem("login-user") || null;
    if(!login && !login_user){
      // Call sharedLoginFn within useEffect
      sharedLoginFn();
    }
  }, [dispatch]);
  
  return (
    <>
      <Provider store={Store} >
          <RouterData />
          <ToastContainer />
      </Provider>
    </>
  );
};

export default App;
