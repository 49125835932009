import { Navigate, Outlet } from "react-router-dom";
import { FRONTEND_MAIN_URL } from "../Utils";

const PrivateRoute = () => {
  const login = JSON.parse(localStorage.getItem("login")!) || false;
  console.log("loginlogin", login);
  
  // if (!login) {
  //   // Redirect to the external login page
  //   window.location.href = `${FRONTEND_MAIN_URL}/auth/login`;
  //   return null; // Prevent further rendering
  // }

  return <Outlet />;
};

export default PrivateRoute;
